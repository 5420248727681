import logo from './assets/bg.png';
import './App.css';

function App() {
  return (
    <div className="App">
       <img src={logo} className="App-logo" alt="logo" />
       <h1>Kommer snart</h1>
      <p style={{color: "#fff"}}>
        Vi hjälper till med allt inom VVS.
      </p>
      <p style={{color: "#fff"}}>
        Besiktningar & Kalkyler.
      </p>

      <p style={{margin: "0", color: "#fff"}}>
        Kontakta oss gärna om du har några frågor:
      </p>
      <p style={{margin: "0", color: "#fff"}}>
        076-8566017
      </p>
      <p style={{margin: "0", color: "#fff"}}>
        vskapacitet@gmail.com
      </p>
    </div>
  );
}

export default App;
